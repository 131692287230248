<h1>Privacy</h1>
<div>
  <div class="[ stack stack-space-2xl ]">
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Inleiding </h2>
        <p>
          In dit document worden er meer details gegeven over de manier waarop er met jouw 'persoonsgegevens' wordt
          omgegaan. Ofwel bewust, ofwel onbewust geef je als gebruiker van POLPO steeds gegevens vrij die ons toelaten
          om jou als persoon te identificeren, ongeacht of we dit effectief doen of niet.
        </p>
        <p>
          Die Keure, de organisatie achter POLPO, hecht een groot belang aan de wijze waarop er met jouw
          persoonsgegevens omgesprongen wordt. Ook de wetgever onderstreept het belang hiervan, en heeft een aantal
          verplichtingen hieromtrent neergeschreven in de 'Europese Algemene Verordening Gegevensverwerking' (ook gekend
          als 'GDPR'). In het onderstaande document komen de verschillende aspecten aan bod die deel uitmaken van deze
          verordening.
        </p>
        <p>
          De policy is geschreven op een zo duidelijk mogelijke manier, ook voor leerlingen. We blijven er echter van
          uitgaan dat er af en toe nog vragen overblijven. Als er woorden of zinnen zijn die niet duidelijk zijn, of in
          het algemeen als je meer informatie wil, neem dan zeker contact op met onze helpdesk via
          <a href="mailto:polpo@diekeure.be">POLPO@diekeure.be</a>
        </p>
        <p> Die Keure, de organisatie achter KABAS is de volgende onderneming: </p>
        <p>
          Die Keure NV <br />
          Kleine Pathoekeweg 3 <br />
          8000 Brugge België <br />
          BTW 0405.108.325
        </p>
        <p>
          Indien je als bezoeker gebruik maakt van POLPO, of indien je besluit jezelf te registreren of ervoor kiest
          gegevens op POLPO mee te delen, verklaar je jezelf akkoord met het gebruik van de door jou opgegeven gegevens
          in overeenstemming met deze verklaring. Houd er rekening mee dat POLPO links kan bevatten naar andere websites
          die mogelijks niet onderworpen zijn aan deze verklaring en waarvoor Die Keure dus niet aansprakelijk of
          verantwoordelijk voor is. Een voorbeeld in dit geval is wanneer een leerkracht een bundel maakt met verwijzing
          naar een andere website.
        </p>
        <p>
          Als die Keure deze verklaring wijzigt nadat je die eerder goedkeurde, dan zal die Keure er alles aan doen om
          je die wijziging ook te laten weten. Wanneer je na de wijziging inlogt zal je via een banner of via een pop-up
          op de hoogte gebracht worden van de nieuwe verklaring. Op dat moment zal je verplicht zijn om eerst de nieuwe
          verklaring door te lezen en een goedkeuring te geven, voordat je verder kan werken op POLPO.
        </p>
        <p>
          Die Keure wil graag benadrukken dat zij zich bewust is van de moeilijkheden en uitdagingen waarmee ouders
          (en/of bevoegde meerderjarigen zoals leerkrachten) mogelijks worden geconfronteerd bij het hedendaagse
          internetgebruik van minderjarige kinderen/ respectievelijk leerlingen. Die Keure stelt dan ook alles in het
          werk om een zo veilig mogelijke Internetomgeving te creëren voor minderjarigen met inachtneming van een
          gezonde dosis voorzichtigheid bij het ontwerpen van de inhoud van POLPO en het nodige respect bij de
          verwerking van de gegevens van deze minderjarigen.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Het verzamelen van gegevens en bijhorende goedkeuringen </h2>
        <p>
          Indien in deze verklaring gesproken wordt over 'gegevens' kan dit zowel over gegevens gaan van meerderjarigen
          als van minderjarigen.
        </p>
        <p>
          Zowel leerkrachten als leerlingen moeten akkoord gaan met deze verklaring voordat zij gebruik kunnen maken
          POLPO. Zelfs al zijn ouders en bevoegde meerderjarigen aansprakelijk voor de handelingen van minderjarigen,
          ook de studenten moeten altijd een expliciete goedkeuring geven vooraleer zij POLPO kunnen gebruiken. Die
          Keure volgt hiermee de redenering dat kinderen vanaf 12 jaar een 'onderscheidingsvermogen' hebben. Dat is ook
          de reden waarom er een aparte goedkeuring gevraagd wordt aan iedere minderjarige die POLPO gebruikt.
        </p>
        <h3
          >Hoe gaat die Keure om met de goedkeuring van ouders voor de verwerking van persoonsgegevens als de ouders
          zelf niet met POLPO werken?</h3
        >
        <p>
          Die Keure gaat ervan uit dat de goedkeuring door de ouders via de school tot stand komt, aangezien de school
          de verantwoordelijke voor de verwerking van persoonsgegevens is.
        </p>
        <h3>Over welke persoonsgegevens gaat het eigenlijk?</h3>
        <p>
          Die Keure verzamelt via POLPO persoonsgegevens. Die Keure zal niet meer gegevens verzamelen dan nodig voor
          haar opdracht t.a.v. de scholen (waarbij de school de verantwoordelijke van de verwerking is). In het kader
          van deze opdracht zal die Keure de volgende persoonsgegevens verzamelen:
        </p>
        <ul class="[ title-small ]">
          <li>
            <b>Naam</b> wordt opgeslagen om de gebruikers te identificeren en te onderscheiden van elkaar. Het
            garandeert ook een vlotte communicatie tussen leerkracht en leerling. Daarnaast worden deze gegevens ook
            gebruikt om iemand snel te identificeren indien hij of zij de helpdesk contacteert. Ook scores van
            resultaten worden aan de naam gekoppeld (en indien gewenst ook doorgestuurd naar het LVS binnen
            Smartschool).
          </li>
          <li><b>Adres</b> wordt enkel en alleen opgeslagen voor facturatiedoeleinden.</li>
          <li> <b>E-mailadres</b> wordt gebruikt als identificatie voor een account. </li>
          <li> <b>IP-adres</b> wordt gebruikt voor rapporteringsdoeleinden. </li>
        </ul>
        <p>
          Die Keure verzamelt geen gevoelige persoonsgegevens over jou (zoals gegevens over ras, politieke opvattingen,
          gezondheid, godsdienstige en andere geloofsovertuigingen of seksuele geaardheid)
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>De school als verantwoordelijke voor de verwerking van persoonsgegevens </h2>
        <p>
          Aangezien de school verantwoordelijk is voor de verwerking van de persoonsgegevens in KABAS (zowel
          leerkrachten als leerlingen) is het ook aan de school om de gebruikers correct te informeren over het gebruik
          van deze hun persoonsgegevens. Eventuele uitoefeningen van rechten (bijvoorbeeld om gewist te worden als
          gebruiker) dienen gericht te zijn aan de school, aangezien het de school is die de gegevens registreert en
          onderhoudt.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Die Keure als verwerker van persoonsgegevens op eigen initiatief</h2>
        <p class="[ title-small ]"
          >Die Keure zal persoonsgegevens op eigen initiatief steeds zo weinig mogelijk gebruiken, en zal altijd
          duidelijk zijn wanneer ze dit wel doet. De reden om persoonsgegevens als niet-verantwoordelijke toch te
          gebruiken heeft te maken direct marketing, met de goede technische en inhoudelijke werking van het systeem,
          alsook met veiligheid (bijvoorbeeld de detectie van fraude).
        </p>
        <p>Meer bepaald kunnen persoonsgegevens gebruikt worden voor de volgende doeleinden:</p>
        <ul class="[ title-small ]">
          <li>bezoekersadministratie (o.a. bevestigingsmail betreffende inloggegevens)</li
          ><li>
            directe marketing naar leerkrachten (nooit naar leerlingen) waaronder het verzenden van minstens één
            nieuwsbrief (iedereen kan zich altijd uitschrijven bij het ontvangen van een nieuwsbrief). Die Keure baseert
            zich op het gerechtvaardigd belang om directe marketing te versturen.
          </li>
          <li>het verbeteren van de inhoud en de kwaliteit van POLPO. </li>
          <li
            >algemene monitoring van de acties ondernomen op het onderwijsplatform door de verschillende gebruikers
            (leerlingen en leerkrachten).
          </li>
          <li
            >het accuraat bijhouden, verbeteren en vervolledigen van de persoonsgegevens zoals door de wet vereist.
          </li>
          <li>Het melden van een data-lek aan de verantwoordelijke van de verwerking. </li>
        </ul>
        <h3>Opslag en bewaring van de persoonsgegevens </h3>
        <p>
          De persoonsgegevens van de gebruikers worden bewaard via de public cloud (Amazon Web Services). Gegevens
          worden bewaard in Ierland binnen de EU zone. Die Keure is gebonden aan het AWS customer agreement, waarin
          gesteld wordt dat de nodige maatregelen genomen worden door AWS tegen verlies van data, diefstal van data of
          ongeoorloofd gebruik van data. </p
        ><p>
          Indien je meer informatie wil over het AWS customer agreement, neem dan contact op via
          <a href="mailto:polpo@diekeure.be">polpo@diekeure.be</a>.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Het doorgeven van persoonsgegevens aan sub-verwerkers. </h2>
        <p>
          Die Keure zal geen gegevens met derden delen voor secundaire of niet gerelateerde doeleinden tenzij anders
          vermeld op het moment van het verzamelen van deze gegevens. De werknemers van die Keure of de door die Keure
          ingeschakelde derden zijn verplicht om de vertrouwelijkheid van de gegevens op POLPO te respecteren.</p
        ><p>
          POLPO als platform maakt gebruik van geïntegreerde functionaliteiten en hulpmiddelen die aangeleverde worden
          door derde partijen. Die Keure gaat enkel een samenwerking aan met derde partijen indien deze gekend zijn als
          betrouwbare partijen als het gaat om de beveiliging en de correcte verwerking van de persoonsgegevens.</p
        ><p>
          Waar gepast of vereist, kunnen en zullen persoonsgegevens door die Keure worden verstrekt aan de
          rechtshandhaving, regelgevende of andere overheidsinstanties, of derde partijen indien dit noodzakelijk of
          wenselijk is om te voldoen aan wettelijke of reglementaire verplichtingen of in het kader van de hierboven
          vermelde doeleinden.
        </p>
        <p>
          Voor specifieke verklaringen van derde partijen verwijzen we naar de verklaringen van onderstaande gekende
          partijen. Deze lijst kan toekomstig nog aangepast worden:
        </p>
        <h3>Integratie met Smartschool (Smartbit B.V.B.A.)</h3>
        <p>
          De eerder vermelde persoonsgegevens zijn deels afkomstig uit Smartschool. Deze gegevens zullen nooit op
          initiatief van die Keure doorgestuurd worden naar POLPO, noch op initiatief van Smartbit. Het is de school, en
          meer specifiek de smartschoolbeheerder die toelating geeft om de gegevens te mogen gebruiken.</p
        ><p>
          Privacy statement Smartbit BVBA:
          <a href="https://www.smartschool.be/privacybeleid/" target="_blank"
            >https://www.smartschool.be/privacybeleid/</a
          >
        </p>
        <h3> Integratie met Google Analytics </h3>

        <p>
          Log-informatie worden doorgegeven en verwerkt door Google Analytics voor analytische doeleinden (bijv. om het
          bezoek aan de website op te volgen (daluren, piekmomenten,..). Met log-informatie bedoelen we informatie zoals
          'tijdstip' en 'IP-adres'. Ook locatiegegevens worden doorgegeven, zodat jouw vermoedelijke locatie op een
          kaart kan worden weergegeven. Ook informatie met betrekking tot het gebruikte toestel zoals hardware en
          software informatie wordt gebruikt om het gebruik van POLPO te monitoren.</p
        ><p>
          Google Analytics :
          <a href="http://www.google.com/analytics/terms/nl.html" target="_blank"
            >http://www.google.com/analytics/terms/nl.html</a
          >
        </p>
        <h3>Integratie met derde partijen met betrekking tot Single Sign On functionaliteit</h3>
        <p
          >Gebruikers die met POLPO en afgeleide producten werken hebben de mogelijkheid om inloggegevens van een aantal
          derde partijen te hergebruiken, door goedkeuring te geven aan een derde partij (zoals bijvoorbeeld facebook)
          om gegevens door te sturen naar POLPO. Deze goedkeuring kan ten allen tijd geannuleerd worden via de derde
          partij.</p
        >
        <p
          >De koppelingen zorgen ervoor dat inloggen eenvoudiger wordt, omdat gegevens hergebruikt worden in plaats van
          deze apart te moeten ingeven. Die Keure biedt Single Sign On functionaliteit aan enkel en alleen om het
          inloggen te vergemakkelijken. Daarom heeft Die Keure enkele basisgegevens nodig (zoals naam, userid,
          geboortedatum). Indien Die Keure in de toekomst andere gegevens wil gebruiken dan zal Die Keure er voor zorgen
          dat de bedoeling hiervan op voorhand duidelijk gemaakt wordt.</p
        >
        <p
          >Die Keure raadt aan om de verklaringen van de betreffende partijen door te nemen alvorens goedkeuring te
          geven om gegevens door te sturen naar POLPO</p
        >
        <p
          >Smartschool:
          <a href="http://www.smartschool.be/privacybeleid/">http://www.smartschool.be/privacybeleid/</a></p
        >
        <p
          >Facebook:
          <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a></p
        >
        <p>Google: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Goede technische werking </h2>
        <p class="[ title-small ]"
          >Met het oog op een degelijke en adequate besturing van POLPO en teneinde een verbeterde navigatie mogelijk te
          maken binnen POLPO, is het mogelijk dat die Keure of die Keure's service providers zogenaamde cookies zullen
          gebruiken. Die Keure maakt gebruik van de volgende cookies:
        </p>
        <ul class="[ title-small ]">
          <li> authentication cookies </li
          ><li> load balancing session cookies </li>
        </ul>
        <p>
          Cookies zijn kleine tekstbestanden of informatiedeeltjes die opgeslagen worden op de harde schijf van een
          gebruiker. Cookies zijn bestanden die als het ware de sporen bijhouden van de voorafgaande bezoeken van
          bepaalde websites en de informatie die met deze bezoeken gepaard gaat. Er bestaan technische cookies
          (bijvoorbeeld m.b.t. taalinstellingen), sessiecookies (tijdelijke cookies) en tracking cookies (cookies die
          betrekking hebben op het gedrag van een website-bezoeker die deze bezoekers a.h.w. op POLPO gaan volgen en de
          gedragingen bijhouden met het oog op een optimale gebruikservaring voor POLPO-bezoeker).
        </p>
        <p>
          Web beacons zijn elektronische beelden die het mogelijk maken het aantal bezoekers op een bepaalde websites te
          tellen en die toegang verlenen tot bepaalde cookies.
        </p>
        <p>
          Die Keure kan deze instrumenten gebruiken om informatie op haar systemen op te volgen en om categorieën van
          gebruikers te identificeren aan de hand van gegevens zoals het IP-adres, het domein, het type van browser en
          de bezochte pagina's. Deze informatie wordt meegedeeld aan Die Keure's webmasters die deze informatie
          gebruiken om het aantal bezoekers op de verschillende delen van POLPO te analyseren en om ervoor te zorgen dat
          POLPO functioneert als een bruikbare en effectieve bron van informatie.
        </p>
        <h3>Soorten cookies</h3>
        <ul class="[ title-small ]">
          <li>
            Functionele <br />
            Functionele cookies helpen een website gebruiksvriendelijk te maken doordat ze basisfunctionaliteit, zoals
            toegang verlenen tot een bepaalde zone van de website, mogelijk maken. De website kan zonder deze cookies
            niet goed functioneren.
          </li>
          <li>
            Voorkeuren <br />
            Dit zijn cookies die informatie over je keuzes en voorkeuren op een website bijhouden. Zo onthouden ze
            bijvoorbeeld je taalkeuze, inloggegevens of de regio waarin je woont.
          </li>
          <li>
            Marketing <br />
            Marketing cookies worden gebruikt om een bezoeker te volgen over meerdere websites. Het is de bedoeling
            advertenties weer te geven die relevant en aantrekkelijk zijn voor de individuele gebruiker en daardoor
            waardevoller zijn voor uitgevers en externe adverteerders.
          </li>
          <li>
            Statistieken <br />
            Statistische cookies verzamelen informatie over het gedrag van websitebezoekers en de prestaties van de
            website. Aan de hand van de resultaten kan de website verbeterd worden en krijgen bezoekers een betere
            gebruikservaring.
          </li>
          <li>
            Sociale media / derde partij <br />
            Social media cookies worden gebruikt om de integratie van sociaalnetwerksites op onze websites mogelijk te
            maken. Onze website kan ingebedde elementen van derde partijen bevatten. Dat kan gaan om inhoud die bij een
            andere partij opgeslagen is, maar die via onze website wordt getoond. Die derden kunnen via onze website
            cookies plaatsen om gegevens te verzamelen. Wijzelf hebben geen invloed op wat die sociale media met hun
            cookies doen. Voor meer informatie over deze cookies verwijzen we je graag naar de verklaringen die de
            derden hierover op hun eigen websites geven. Merk wel op dat die verklaringen regelmatig kunnen wijzigen.
          </li>
        </ul>
        <h3>Cookies die wij gebruiken op POLPO.be: </h3>
        <ul class="[ title-small ]">
          <li
            >Statistieken: wordt gebruikt om Google Analytics te integreren.
            <ul>
              <li
                >IDE:
                <ul>
                  <li>provider: Google (Doubleclick)</li>
                  <li
                    >doel: wordt gebruikt om de gebruiker zijn of haar acties te registereren en rapporteren na het
                    kijken of klikken van een advertentie met als doel het meten van de efficiëntie van een advertentie
                    naar de gerichte persoon.</li
                  >
                  <li>levensduur: 1 jaar</li>
                  <li>type: HTTP cookie</li>
                </ul>
              </li>
              <li
                >_ga:
                <ul>
                  <li>provider: Google Tag Manager / Google</li>
                  <li
                    >doel: registreert een uniek ID dat gebruikt wordt om statistische data te genereren hoe een
                    gebruiker de website gebruikt.</li
                  >
                  <li>levensduur: 2 jaar</li>
                  <li>type: HTTP cookie</li>
                </ul>
              </li>
              <li
                >_gat:
                <ul>
                  <li>provider: Google Tag Manager / Google</li>
                  <li>doel: wordt gebruikt door Google Analytics om de verzoeksnelheid te vertragen.</li>
                  <li>levensduur: 2 jaar</li>
                  <li>type: HTTP cookie</li>
                </ul>
              </li>
              <li
                >_gid:
                <ul>
                  <li>provider: Google Tag Manager / Google</li>
                  <li
                    >doel: registreert een uniek ID dat gebruikt wordt om statistische data te genereren hoe een
                    gebruiker de website gebruikt. (meer bepaald het groeperen van gedrag)</li
                  >
                  <li>levensduur: 1 dag</li>
                  <li>type: HTTP cookie</li>
                </ul>
              </li>
            </ul>
          </li>
          <li> 3rd party: wordt gebruikt om de Wistia media player te integreren en media af te spelen. </li>
        </ul>

        <p
          >Een gebruiker van POLPO kan altijd het gebruik van de cookies verwijderen via zijn of haar
          browserinstellingen.</p
        >
        <ul class="[ title-small ]">
          <li>
            <a
              href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen"
              target="_blank"
              >Mozilla Firefox
            </a> </li
          ><li>
            <a
              href="https://support.microsoft.com/nl-nl/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              >Internet Explorer
            </a> </li
          ><li> <a href="https://support.google.com/chrome/answer/95647?hl=nl" target="_blank">Google Chrome </a> </li
          ><li> <a href="https://support.apple.com/nl-nl/HT201265" target="_blank">Safari (iOS) </a> </li
          ><li>
            <a href="https://support.apple.com/nl-be/guide/safari/sfri11471/mac" target="_blank"
              >Safari (macOS)
            </a> </li
          ><li>
            <a
              href="https://support.microsoft.com/nl-nl/help/10607/windows-10-view-delete-browser-history-microsoft-edge"
              target="_blank"
              >Edge
            </a>
          </li>
        </ul>
        <p
          >Die Keure voorziet in een banner bij het eerste bezoek van de website waarin toestemming wordt gevraagd voor
          het plaatsen van cookies.</p
        >
        <p>
          Noch de cookies noch de web beacons gebruikt op POLPO verzamelen de naam of het e-mailadres van de bezoeker.
          Onder bepaalde omstandigheden is het echter mogelijk dat de bezoekers geen toegang verkrijgen tot bepaalde
          delen van POLPO, indien hun webbrowser zodanig is ingesteld dat ze cookies weigeren.
        </p>
      </div>
    </section>
    <section>
      <div class="[ stack stack-space-s ]">
        <h2>Wat zijn jouw rechten als gebruiker? </h2>
        <p
          >Je hebt als leerling of als leerkracht een aantal rechten die je kan gebruiken als het over persoonsgegevens
          gaat.</p
        >
        <p>
          Voor al je vragen over jouw rechten kan je een email sturen naar:
          <a href="mailto:polp@diekeure.be">POLPO@diekeure.be</a> Mogelijks wens je je gegevens aan te passen, of wil je
          gegevens permanent verwijderen en dergelijke meer. Aarzel niet om contact op te nemen met onze klantendienst.
          Om bepaalde rechten uit te oefenen zullen wij mogelijks verwijzen naar de school als verantwoordelijke van de
          verwerking.
        </p>
        <h3>Rechtmatige en veilige verwerking van persoonsgegevens </h3>
        <p>
          Die Keure zal er op toezien dat de gegevensverwerking gebeurd op basis van het gezond verstand (doeltreffend,
          niet overmatig).</p
        ><p>
          De persoonsgegevens zullen niet langer worden opgeslagen dan nodig. Zolang je account actief is zullen we de
          persoonsgegevens bijhouden. Wanneer leerkrachten en leerlingen hun account niet vernieuwen houden we de
          persoonsgegevens echter nog langer bij ons zodanig dat wanneer iemand alsnog (na het verlopen van een
          abonnement) zijn account wil vernieuwen nog gebruik kan maken van de door hem of haar opgeslagen
          functionaliteiten. Zo zal je als leerkracht je bundels of taken niet verliezen, en kan je nog steeds de
          resultaten bekijken van de leerlingen van de voorbije jaren.</p
        ><p>
          Die Keure heeft naar haar mening voldoende beveiligingsmaatregelen getroffen om een veilige verwerking van de
          persoonsgegevens te waarborgen.</p
        ><p>
          De risico's van een toevallige of ongeoorloofde vernietiging, toevallig verlies, de wijziging van of de
          toegang tot, en iedere andere niet toegelaten verwerking zijn zo goed mogelijk gereduceerd. Dit betekent
          helaas niet dat alle risico's uitgesloten kunnen worden. Bij een inbreuk op de wettelijk toegestane
          verwerkingen in POLPO, zal die Keure onmiddellijk alle mogelijke maatregelen nemen om schade tot een minimum
          te beperken.
        </p>
        <h3>Recht op verzet, restrictie en gegevensoverdracht </h3>
        <p>
          Je kan je als gebruiker te allen tijde kosteloos en zonder motivering verzetten tegen de verwerking van je
          persoonsgegevens indien 1) die Keure je persoonsgegevens verwerkt op basis van een legitiem doel (en die Keure
          de persoonsgegevens niet verder moet verwerken op een andere juridische basis); of 2) deze persoonsgegevens
          verkregen werden met het oog op direct marketing. </p
        ><p>
          In de beperkte gevallen waar dit wettelijk is toegelaten, kan je als gebruiker ook een verbod van de
          verwerking eisen. </p
        ><p>
          Verder kan je als gebruiker in bepaalde gevallen zoals bepaald door de wet ook de gegevensoverdracht eisen van
          de persoonsgegevens die je aan die Keure hebt verstrekt. Die Keure verbindt zich ertoe je deze
          persoonsgegevens op een gestructureerde, gangbare en machineleesbare vorm mee te delen, zodat je deze
          persoonsgegevens indien gewenst kan doorgeven aan een andere verwerkingsverantwoordelijke.</p
        ><p>
          Als gebruiker kan je je rechten uitoefenen door in eerste instantie je school te contacteren, aangezien zij de
          verantwoordelijke van de verwerking zijn. Die Keure verbindt er zich toe om zo snel mogelijk aan het verzoek
          van een school gevolg te geven zoals voorzien in de wet.
        </p>
        <h3> Recht op toegang tot, verbetering en verwijdering van je persoonsgegevens </h3>
        <p>
          Je beschikt over een recht op toegang tot je persoonsgegevens. Die Keure engageert zich tot een zo nauwkeurig
          mogelijke gegevensverzameling. Onnauwkeurige of onvolledige persoonsgegevens kunnen steeds verbeterd of
          eventueel zelfs verwijderd worden.</p
        ><p>
          Jij als gebruiker hebt het recht onnauwkeurigheden en onvolledigheden te melden indien de nodige aanpassingen
          niet door jou binnen je gebruikersregistratie of profiel gemaakt kunnen worden.</p
        ><p>
          Als gebruiker kan je je rechten uitoefenen door ons te contacteren via polpo@diekeure.be Die Keure verbindt er
          zich toe zo snel mogelijk aan jouw verzoek een gevolg te geven. Omwille van veiligheidsredenen zullen we je in
          deze procedure wel vragen je identiteit te bewijzen.
        </p>
        <h3>Recht om klacht in te dienen bij de Privacy-commissie </h3>
        <p>
          Je hebt als gebruiker het recht om een klacht in te dienen bij de Belgische Privacy-commissie, ook de
          ‘Commissie voor de bescherming van de persoonlijke levenssfeer’ genaamd (zie
          <a href="https://www.gegevensbeschermingsautoriteit.be/burger"
            >https://www.gegevensbeschermingsautoriteit.be/burger</a
          >
          voor meer informatie).
        </p>
      </div>
    </section>
  </div>
</div>
