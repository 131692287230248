import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { NavigationCoreComponent } from '../navigation-core.component';
import { NavigationTreeDataSource } from './navigation-tree-data-source';
import { NavItem } from './navigation-tree-item.interface';

@Component({
  selector: 'campus-navigation-tree',
  templateUrl: './navigation-tree.component.html',
  styleUrls: ['./navigation-tree.component.scss'],
})
export class NavigationTreeComponent extends NavigationCoreComponent implements OnInit, OnChanges {
  dataSource: NavigationTreeDataSource;
  treeControl: FlatTreeControl<NavItem, number | string>;

  dragging = false;
  draggedNodeWasExpanded = false;
  validateDrop = false;
  expandTimeout = 2000;
  expandDelay = 500;

  itemsDict: Dictionary<NavItem> = {};

  @Input() public items: NavItem[] = [];
  @Input() public trackByFn = this.trackById;

  @Output() public moveItem = new EventEmitter();

  public expandedNodes = new Set<string | number>();

  ngOnInit(): void {
    this.setDataSource(this.items);
    this.setTreeControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      this.itemsDict = groupArrayByKeys(this.items, ['id'], null, true);
      this.setDataSource(this.items);
    }
  }

  public hasChildren = (_: number, node: NavItem): boolean => node.hasChildren;

  private getParent(node: NavItem): NavItem | null {
    return this.itemsDict[node.parentId];
  }

  private getContextActions(node: NavItem): any[] {
    return [
      { label: 'Create', handler: (item) => console.log('Create', item) },
      { label: 'Edit', handler: (item) => console.log('Edit', item) },
      { label: 'Delete', handler: (item) => console.log('Delete', item) },
    ];
  }

  public shouldRender(node: NavItem): boolean {
    let ancestor = this.getParent(node);
    while (ancestor) {
      if (!this.treeControl.isExpanded(ancestor)) return false;
      ancestor = this.getParent(ancestor);
    }
    return true;
  }

  private trackById(index: number, item: NavItem) {
    return item.id;
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   * */
  drop(event: CdkDragDrop<string[]>) {
    if (!event.isPointerOverContainer) return;

    const flatItems = this.dataSource.getData();

    const draggedItem = flatItems[event.previousIndex];
    const targetItem = flatItems[event.currentIndex];

    const data = {
      draggedItem,
      targetItem,
    };

    this.moveItem.emit(data);

    moveItemInArray(flatItems, event.previousIndex, event.currentIndex);
    this.dataSource.setData(flatItems);
  }

  dragStart(node, event) {
    this.dragging = true;
    if (this.treeControl.isExpanded(node)) {
      this.draggedNodeWasExpanded = true;
      this.treeControl.collapse(node);
    }
  }

  dragEnd(node, event) {
    this.dragging = false;
    if (this.draggedNodeWasExpanded) {
      this.treeControl.expand(node);
    }
    this.draggedNodeWasExpanded = false;
  }

  dragHover(node) {
    if (!this.dragging) return;

    clearTimeout(this.expandTimeout);
    this.expandTimeout = setTimeout(() => {
      this.treeControl.expand(node);
    }, this.expandDelay);
  }

  dragHoverEnd() {
    if (!this.dragging) return;

    clearTimeout(this.expandTimeout);
  }

  private setDataSource(data: NavItem[]) {
    this.dataSource = new NavigationTreeDataSource(data, (node) => this.getContextActions(node));
  }

  private setTreeControl() {
    this.treeControl = new FlatTreeControl<NavItem, number | string>(
      (node) => node.depth,
      (node) => this.hasChildren(null, node),
      {
        trackBy: (node) => this.trackByFn(null, node),
      }
    );
  }
}
