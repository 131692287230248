<div
  class="[ w-full flex gap-s items-center ][ surface ][ m-bs-0 p-is-m p-ie-s p-b-s ] [ corner-top-s ]"
  data-cy="table-header"
>
  <div
    class="[ title-large truncate ][ transition-all duration-short-3 ]"
    [ngClass]="!disableGrowSearchOnFocus && (searchFocus || searchField.value) ? ['w-0'] : ['basis-1/2', 'grow']"
    >{{ headline }}</div
  >
  <div class="[ flex gap-2xs basis-1/2 grow ]">
    <ng-content select="[beforeSearch]"></ng-content>

    <campus-text-field
      #searchField
      type="search"
      class="[ flex-1 ]"
      [disabled]="showOnlySelection"
      (valueChange)="onSearchTermChange($event)"
      (focusChange)="searchFocus = $event"
      data-cy="table-search-field"
    ></campus-text-field>

    <ng-content select="[afterSearch]"></ng-content>

    <button
      *ngIf="filterableColumns.length"
      inline-icon-button
      icon="cds-comp:filter"
      [class.selected]="showFilters"
      [badge]="filtersForm?.controls.length"
      [disabled]="showOnlySelection"
      (click)="showFilters = !showFilters"
      [plainTooltip]="showFilters ? 'Filters verbergen' : 'Filters tonen'"
      data-cy="btn-table-toggle-filters"
    ></button>

    <ng-content select="[afterFilters]"></ng-content>

    <ng-container *ngIf="selectableColumns.length">
      <button inline-icon-button icon="cds-comp:tune" #tune plainTooltip="Kolommen"></button>
      <campus-menu
        [anchor]="tune"
        positioning="popover"
        [multiple]="true"
        (selectionChange)="onVisibleColumnsChange($event)"
      >
        <campus-menu-item
          *ngFor="let column of selectableColumns"
          checkbox
          [label]="column.label"
          [value]="column"
          [disabled]="column.alwaysVisible"
          [selected]="column.visible || column.alwaysVisible"
        ></campus-menu-item>
      </campus-menu>
    </ng-container>

    <ng-content select="[afterColumns]"></ng-content>

    <button
      #refresh
      *ngIf="showRefresh"
      inline-icon-button
      icon="cds-comp:refresh"
      plainTooltip="Opnieuw zoeken"
      (click)="onRefresh($event)"
      data-cy="btn-table-refresh"
    ></button>

    <button
      *ngIf="selectable"
      inline-icon-button
      icon="cds-comp:show-selection"
      [disabled]="!selectableController.selected.length && !showOnlySelection"
      [badge]="selectableController.selected.length"
      [class.selected]="showOnlySelection"
      (click)="onToggleShowOnlySelection()"
      [plainTooltip]="showOnlySelection ? 'Alle resultaten tonen' : 'Alleen geselecteerde tonen'"
      data-cy="btn-table-toggle-show-selection"
    ></button>

    <ng-content select="[afterShowOnlySelection]"></ng-content>

    <div class="[ relative ]" *ngIf="selectableController.selected?.length && getBulkActions()?.length > 0">
      <button inline-icon-button icon="cds-comp:more-vert" #moreBulkActions data-cy="btn-table-bulk-actions"> </button>
      <campus-menu [anchor]="moreBulkActions" positioning="popover">
        <campus-menu-item
          *ngFor="let action of getBulkActions()"
          [label]="action.label"
          [leadingIcon]="action.icon"
          (click)="onBulkClick(action)"
        ></campus-menu-item>
      </campus-menu>
    </div>

    <ng-content select="[afterBulkActions]"></ng-content>
  </div>
</div>

<div class="[ overflow-auto surface-container stack p-b-s p-i-m ]" *ngIf="showFilters && !showOnlySelection">
  <div
    class="[ flex gap-2xs items-center ]"
    *ngFor="let filter of filtersForm.controls; let i = index"
    [formGroup]="filter"
    data-cy="table-filter"
  >
    <campus-select formControlName="key" closeOnSelect menuPositioning="popover">
      <campus-select-option
        *ngFor="let column of filterableColumns"
        [value]="column.key"
        [label]="column.label"
      ></campus-select-option>
    </campus-select>

    <span class="label-large">=</span>

    <ng-container *ngIf="columnConfigDict[filter.get('key')?.value] as columnConfig">
      <campus-select
        *ngIf="columnConfig?.filterOptions?.length; else noArray"
        formControlName="value"
        menuPositioning="popover"
        class="[ flex-1 ]"
        multiple
        checkbox
        clearable
        [compareWith]="filterOptionCompareWith"
      >
        <campus-select-option
          *ngFor="let option of columnConfig.filterOptions"
          [value]="option"
          [label]="option.label"
        ></campus-select-option>
      </campus-select>

      <ng-template #noArray>
        <ng-container [ngSwitch]="columnConfig?.type">
          <campus-switch *ngSwitchCase="'boolean'" formControlName="value"></campus-switch>
          <campus-text-field
            *ngSwitchCase="'number'"
            type="number"
            class="[ flex-1 ]"
            formControlName="value"
          ></campus-text-field>
          <campus-text-field *ngSwitchDefault class="[ flex-1 ]" formControlName="value"></campus-text-field>
        </ng-container>
      </ng-template>
    </ng-container>

    <button inline-icon-button icon="cds-comp:remove-circle" (click)="onRemoveFilter(i)" class="[ m-is-auto ]"></button>
  </div>

  <div class="[ flex justify-between items-center ]">
    <button outline-button leadingIcon="cds-comp:add" (click)="onAddFilter()">Filter toevoegen</button>
    <button
      inline-button
      leadingIcon="cds-comp:clear"
      (click)="onRemoveAllFilters()"
      [disabled]="!filtersForm.controls.length"
    >
      Filters wissen
    </button>
  </div>
</div>

<div
  class="[ flex-1 ][ w-full ][ overflow-auto border-bs ]"
  #scrollContainer
  data-cy="table-content"
  *ngIf="scrolled$ | async as scrolled"
>
  <table class="[ w-full ]">
    <thead
      #headRef
      class="[ sticky top-0 ][ text-left label-medium on-surface-variant ][ index-content-on-sticky ][ transition-elevation ]"
      [class.elevation-2]="scrolled?.y"
      data-cy="table-thead"
    >
      <tr class="[ row-h-m ][]" data-cy="table-thead-row">
        <th
          class="[ sticky left-0 index-content-on-sticky ][ surface-container-low on-surface-variant ][ p-i-s p-b-xs ][ text-center ][ column-w-m ]"
          *ngIf="selectable"
          data-cy="table-cell"
        >
          <campus-checkbox
            [checked]="allVisibleRowsChecked"
            (click)="onCheckAll()"
            [indeterminate]="!allVisibleRowsChecked && someVisibleRowsChecked"
            *ngIf="multiple && showCheckAll"
            aria-hidden="true"
            [disabled]="loading"
          ></campus-checkbox>
          <span
            class="[ absolute inset-0 elevation-0 index-pullback ][ transition-elevation ]"
            [class.elevation-1]="scrolled?.x"
          ></span>
        </th>
        <th
          class="[ surface-container-low on-surface-variant ][ p-0 ][ outline-none ]"
          *ngFor="let column of visibleColumns.selected"
          [attr.tabindex]="column.sortable ? 0 : undefined"
          #sortableHeader
          [attr.aria-sort]="
            sortBy.direction === 'asc' ? 'ascending' : sortBy.direction === 'desc' ? 'descending' : undefined
          "
          [style.width]="column.width"
          [style.minWidth]="column.minWidth"
          [style.maxWidth]="column.maxWidth"
          data-cy="table-cell"
        >
          <div
            class="[ relative ][ flex flex-start items-center gap-2xs ][ p-i-s p-b-xs corner-full ]"
            [class.cursor-pointer]="column.sortable"
            (click)="column.sortable && onSortToggle(column)"
          >
            <div>{{ column.label }}</div>
            <campus-icon
              *ngIf="column.sortable"
              [svgIcon]="sortBy.direction ? 'cds-comp:' + sortBy.direction : null"
              class="[ icon-size-xs ]"
              [ngClass]="[sortBy.direction && sortBy.key === column.key ? 'opacity-full' : 'opacity-none']"
            ></campus-icon>

            <ng-container *ngIf="column.sortable">
              <campus-state-layer [for]="sortableHeader" class="[ bg-primary ]"></campus-state-layer>
              <campus-focus-ring [for]="sortableHeader" inward></campus-focus-ring>
            </ng-container>
          </div>
        </th>
        <th
          *ngIf="hasRowActions"
          class="[ surface-container-low on-surface-variant ][ column-w-m p-is-s p-ie-2xs p-b-xs ]"
          data-cy="table-cell"
        ></th>
      </tr>
    </thead>

    <tbody class="[ surface ]" data-cy="table-tbody">
      <ng-container *ngIf="dataSource?.length; else noData">
        <ng-container *ngIf="groupBy; else notGrouped">
          <ng-container *ngFor="let group of dataSource">
            <tr
              #groupRowRef
              class="[ sticky ][ border-bs ][ surface-container-lowest ][ index-content-on-sticky ]"
              [style.top.px]="headRef.clientHeight - 2"
              data-cy="table-tbody-group-row"
            >
              <td
                class="[ relative sticky left-0 ][ p-i-s p-b-xs ][ body-medium ][ outline-none ]"
                [attr.colspan]="visibleColumns.selected.length + (selectable ? 1 : 0) + (hasRowActions ? 1 : 0)"
                data-cy="table-cell"
              >
                <div class="table-skeleton" *ngIf="loading; else groupCellLoaded"></div>
                <ng-template #groupCellLoaded>
                  <div class="[ flex justify-between items-center w-full sticky ]">
                    <div class="[ flex items-center gap-s ]">
                      <div class="[ transform transition-transform ]" [class.-rotate-90]="!group.open">
                        <button
                          inline-icon-button
                          icon="cds-comp:expand-more"
                          (click)="onToggleCollapseGroup(group)"
                        ></button>
                      </div>

                      <div class="[ flex gap-2xs items-baseline ]">
                        <span class="[ body-small on-surface-variant ]">{{ columnConfigDict[groupBy].label }}: </span>
                        <span class="[ title-medium ]">{{ group[groupBy] }}</span>
                      </div>
                    </div>

                    <div *ngIf="hasGroupActions" class="[ relative ][ p-is-s p-ie-2xs p-b-xs ]">
                      <ng-container *ngIf="getGroupActions(group).length > 0">
                        <div class="[ relative ]">
                          <button #groupActionsRef inline-icon-button icon="cds-comp:more-vert"></button>
                          <campus-menu [anchor]="groupActionsRef" positioning="popover" stopClickPropagationWhenOpen>
                            <campus-menu-item
                              *ngFor="let action of getGroupActions(group)"
                              [label]="action.label"
                              [leadingIcon]="action.icon"
                              (click)="onGroupActionClick(action, group)"
                            ></campus-menu-item>
                          </campus-menu>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-template>
              </td>
            </tr>

            <ng-container
              *ngTemplateOutlet="dataRow; context: { $implicit: group.children, collapsed: !group.open }"
            ></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #noData>
        <ng-container *ngIf="loading; else loadedNoData">
          <tr class="[ border-bs ]" *ngFor="let __ of [0, 1, 2]" data-cy="table-tbody-row-skeleton">
            <td *ngIf="selectable" class="[ p-i-s p-b-xs ][ body-medium ][ outline-none ]">
              <div class="table-skeleton"></div>
            </td>
            <td
              *ngFor="let _ of visibleColumns.selected || [0, 1, 2, 3, 4]"
              class="[ p-i-s p-b-xs ][ body-medium ][ outline-none ]"
            >
              <div class="table-skeleton"></div>
            </td>
            <td *ngIf="hasRowActions" class="[ p-i-s p-b-xs ][ body-medium ][ outline-none ]">
              <div class="table-skeleton"></div>
            </td>
          </tr>
        </ng-container>
        <ng-template #loadedNoData>
          <tr class="[ border-bs ]" data-cy="table-tbody-row-no-data">
            <td
              class="[ p-i-s p-b-xs ][ body-medium ][ outline-none ]"
              [attr.colspan]="visibleColumns.selected.length + (selectable ? 1 : 0) + (hasRowActions ? 1 : 0)"
            >
              Geen resultaten gevonden
            </td>
          </tr>
        </ng-template>
      </ng-template>

      <ng-template #notGrouped>
        <ng-container *ngTemplateOutlet="dataRow; context: { $implicit: dataSource }"></ng-container>
      </ng-template>

      <ng-template #dataRow let-rows let-collapsed="collapsed">
        <tr
          table-row
          #rowRef
          *ngFor="let row of rows; index as i; trackBy: trackById"
          class="[ border-bs ][ row-h-s ]"
          [selectable]="selectable"
          [value]="row"
          [class.collapse]="collapsed"
          [elevateCheckbox]="scrolled?.x"
          [loading]="loading"
          data-cy="table-tbody-row"
          [state]="row.state"
        >
          <td
            #cellRef
            *ngFor="let column of visibleColumns.selected"
            class="[ relative ][ p-i-s p-b-xs ][ body-medium ][ outline-none ]"
            [class.cursor-pointer]="!readonly"
            [tabindex]="column.type === 'link' ? -1 : 0"
            (click)="onRowClick(row)"
            data-cy="table-cell"
          >
            <div class="table-skeleton" *ngIf="loading; else cellLoaded"></div>
            <ng-template #cellLoaded>
              <ng-container *ngTemplateOutlet="cellValue; context: { $implicit: row, column: column }"></ng-container>
              <campus-state-layer *ngIf="!readonly" [for]="rowRef.element" class="[ bg-primary ]"></campus-state-layer>
              <campus-focus-ring [for]="cellRef" inward></campus-focus-ring>
            </ng-template>
          </td>

          <td *ngIf="hasRowActions" class="[ relative ][ p-is-s p-ie-2xs p-b-xs ]" data-cy="table-cell-action">
            <div class="table-skeleton" *ngIf="loading; else rowActionsCellLoaded"></div>
            <ng-template #rowActionsCellLoaded>
              <ng-container *ngIf="getRowActions(row).length > 0">
                <div class="[ relative ]">
                  <button #rowActionsRef inline-icon-button icon="cds-comp:more-vert"></button>
                  <campus-menu [anchor]="rowActionsRef" positioning="popover" stopClickPropagationWhenOpen>
                    <campus-menu-item
                      *ngFor="let action of getRowActions(row)"
                      [label]="action.label"
                      [leadingIcon]="action.icon"
                      (click)="onRowActionClick(action, row)"
                    ></campus-menu-item>
                  </campus-menu>
                </div>
              </ng-container>
              <campus-state-layer *ngIf="!readonly" [for]="rowRef.element" class="[ bg-primary ]"></campus-state-layer>
            </ng-template>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>

<div
  *ngIf="pageSize && pagination"
  class="[ w-full flex gap-xs items-center justify-end ][ surface-container-low ][ m-be-0 p-is-m p-ie-s p-b-2xs ] [ border-bs corner-bottom-s ]"
  data-cy="table-pagination"
>
  <div class="[ label-medium on-surface-variant ]">
    <span #pageRef class="[ relative ][ cursor-pointer ][ p-i-xs p-b-2xs corner-full ]">
      {{ pagination.from }} - {{ pagination.to }} van {{ pagination.total }}
      <campus-menu [anchor]="pageRef" positioning="popover" (selectionChange)="onPageSizeChange($event)">
        <campus-menu-item
          *ngFor="let size of pageSizeOptions"
          [label]="size + ' per pagina'"
          [value]="size"
          [selected]="size === pageSize"
        ></campus-menu-item>
      </campus-menu>
      <campus-state-layer [for]="pageRef" class="[ bg-primary ]"></campus-state-layer>
      <campus-focus-ring [for]="pageRef"></campus-focus-ring>
    </span>
  </div>
  <div class="[ flex ]">
    <button
      inline-icon-button
      icon="cds-comp:page-previous"
      class="[ surface-container-low ]"
      (click)="previousPage()"
      [disabled]="loading || page === 1"
      [plainTooltip]="page > 1 && 'Vorige pagina'"
      data-cy="btn-table-pagination-previous"
    ></button>
    <button
      inline-icon-button
      icon="cds-comp:page-next"
      class="[ surface-container-low ]"
      (click)="nextPage()"
      [disabled]="loading || page * pageSize >= totalItems"
      [plainTooltip]="page * pageSize < totalItems && 'Volgende pagina'"
      data-cy="btn-table-pagination-next"
    ></button>
  </div>
</div>
<!-- </ng-container>
</ng-container>-->

<ng-template #cellValue let-row let-column="column">
  <ng-container [ngSwitch]="column.type">
    <div *ngSwitchCase="'number'" class="w-full">
      {{ row[column.key] }}
    </div>
    <span *ngSwitchCase="'date'" class="body-small on-surface-variant">
      {{ row[column.key] | date: 'dd/MM/yyyy' }}
    </span>
    <span *ngSwitchCase="'currency'" class="label-medium">
      {{ row[column.key] | currency: 'EUR':'symbol':'1.2-2':locale }}
    </span>

    <a
      *ngSwitchCase="'link'"
      [href]="getFormattedHref(column, row)"
      #linkRef
      class="[ relative outline-none ]"
      target="_blank"
    >
      {{ row[column.key] }}
      <campus-focus-ring [for]="linkRef"></campus-focus-ring>
    </a>

    <div *ngSwitchCase="'boolean'" class="flex place-content-center">
      <campus-icon
        [svgIcon]="row[column.key] ? 'cds-comp:boolean-true' : 'cds-comp:boolean-false'"
        [class.icon-opacity-disabled]="!row[column.key]"
      >
      </campus-icon>
    </div>

    <ng-container *ngSwitchCase="'icon'" class="body-medium">
      <campus-icon [svgIcon]="row[column.key]"></campus-icon>
    </ng-container>

    <div
      *ngSwitchDefault
      class="body-medium"
      [ngClass]="column.clamp ? 'line-clamp-' + column.clamp : undefined"
      [richTooltip]="!disableTooltipOnTruncated && row[column.key]"
      [tooltipSubhead]="column.label"
      [tooltipYOffset]="10"
      tooltipPosition="absolute"
      tooltipWhenTruncated
      [tooltipDisabled]="disableTooltip"
    >
      {{ row[column.key] }}
    </div>
  </ng-container>
</ng-template>
