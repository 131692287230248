import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Ripple } from '../../ripple/ripple.decorator';
import { NavigationCoreItemComponent } from '../navigation-core-item.component';

@Component({
  selector: 'campus-navigation-rail-item, [navigation-rail-item]',
  templateUrl: './navigation-rail-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Ripple({ elementRef: 'iconContainer', triggerRef: 'elementRef', disablePressdown: true })
export class NavigationRailItemComponent extends NavigationCoreItemComponent {
  @HostBinding('class')
  defaultClasses = [
    'cds-navigation-rail-item',
    'flex',
    'justify-center',
    'items-center',
    'outline-none',
    'cursor-pointer',
    'group',
  ];
}
