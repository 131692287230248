import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmptyAttribute, InputBooleanAttribute, asEmptyAttribute, isTruthyInput } from '../core/empty-attribute';
import { errorMessages } from '../core/error-messages';
import { FormElementCore } from '../core/form-element-core';

export type TextFieldType = 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'textarea';

@Component({
  selector: 'campus-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextFieldComponent,
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: TextFieldComponent, multi: true },
  ],
})
export class TextFieldComponent extends FormElementCore<string> implements OnInit, OnChanges {
  public errorText: string | Record<string, string> = errorMessages;

  public supportingTextOrErrorText$: Observable<string>;
  public isAutoCounter: boolean;
  public trailingSupportingOrCounterText: string;

  private _inputRight: boolean;

  @ViewChild('input', { static: false, read: ElementRef }) inputEl: ElementRef<HTMLInputElement>;

  @HostBinding('class')
  defaultClasses = ['text-field', 'inline', 'outline-none'];

  @HostBinding('attr.role')
  @Input()
  role = 'presentation';

  @Input() leadingText: string;
  @Input() trailingText: string;
  @Input() supportingText: string;
  @Input() trailingSupportingText: string;
  @Input() label: string;
  @Input() rows = 2;

  @HostBinding('attr.data-type')
  @Input()
  type: TextFieldType;

  @Input() leadingIcon: string;
  @Input() trailingIcon: string;
  @Input() clearable: InputBooleanAttribute;

  @Input() ariaDescribedBy: string;
  @Input() max: number;
  @Input() maxLength: number;
  @Input() min: number;
  @Input() minLength: number;
  @Input() pattern: string;
  @Input() placeholder: string;

  @Input() ariaAutoComplete: 'none' | 'inline' | 'list' | 'both';
  @Input() ariaExpanded: boolean;

  @Input() step: number;

  @HostBinding('attr.data-active')
  @Input()
  active: boolean;

  @Input()
  get inputRight() {
    return this._inputRight;
  }
  set inputRight(value: InputBooleanAttribute) {
    this._inputRight = isTruthyInput(value);
  }

  get element() {
    return this.readOnly ? this.elementRef.nativeElement : this.inputEl?.nativeElement;
  }

  @Output() clear = new EventEmitter<MouseEvent>();

  @HostBinding('attr.data-populated')
  isPopulated: EmptyAttribute;

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (event.defaultPrevented) return;

    this.element.focus();
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.hovered = true;
  }
  @HostListener('mouseleave')
  onMouseLeave() {
    this.hovered = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.supportingTextOrErrorText$ = this.getSupportingTextOrErrorText$();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.trailingSupportingText || changes.maxLength) {
      this.onValueChanged();
    }
    if (changes.clearable) {
      this.clearable = isTruthyInput(changes.clearable.currentValue);
    }
  }

  handleInput(event: Event) {
    this.value = (event.target as HTMLInputElement).value;
    this.touch();
  }

  handleChange(event: Event) {
    this.valueChange.emit((event.target as HTMLInputElement).value);
  }

  validate(control: FormControl) {
    const errors = super.validate(control);
    if (errors) {
      return errors;
    }

    if (this.required && !this.value) {
      return { required: true };
    }

    if (this.type === 'email') {
      if (this.value?.includes('@') === false) {
        return { missingat: true };
      }

      if (!this.value?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/)) {
        return { email: true };
      }
    }

    if (this.type === 'number') {
      if (this.value && this.min !== undefined && +this.value < this.min) {
        return { min: true };
      }

      if (this.value && this.max !== undefined && +this.value > this.max) {
        return { max: true };
      }
    }

    return null;
  }

  onClear(event: MouseEvent) {
    this.value = undefined;
    this.clear.emit(event);
  }

  protected onValueChanged() {
    this.trailingSupportingOrCounterText = this.getTrailingSupportingOrCounterText();
    this.isPopulated = asEmptyAttribute(this.value);
    this.isAutoCounter = this.getIsAutoCounter();
  }

  private getSupportingTextOrErrorText$() {
    return this.status$.pipe(
      map((status) => (status === 'invalid' && this.errorText ? this.getMatchingErrorText() : this.supportingText))
    );
  }

  private getIsAutoCounter() {
    return this.maxLength && !this.trailingSupportingText;
  }
  private getTrailingSupportingOrCounterText() {
    if (this.trailingSupportingText) return this.trailingSupportingText;
    if (this.maxLength) return `${this.value?.length || 0}/${this.maxLength}`;
  }

  private getMatchingErrorText() {
    if (typeof this.errorText === 'string') {
      return this.errorText;
    }

    if (this.formControl.errors) {
      for (const key in this.formControl.errors) {
        if (key in this.errorText) {
          const text: string = this.errorText[key];

          const replaced = text.replace(/\{\{(\w+)\}\}/g, (match: string, captured: string) =>
            this[captured].toString()
          );

          return replaced;
        }
      }
    }
  }
}
